<template>
  <div class="bgwhitealpha shadow-sm rounded p-2 xtarget" id="yeekee">
    <h6 class="text-dark">
      <i class="fas fa-trophy"></i> {{ $t("yeekee_title") }} {{ getTypeYeekee(u8Type) }}
      <span class="badge badge-dark">{{ toDay }}</span>
    </h6>
    <app-mobile-view :dataList="dataList" :isVip="isVip" :u8Type="u8Type" />
    <app-tab-content :dataList="dataList" :isVip="isVip" :u8Type="u8Type" />
    <app-pc-view :dataList="dataList" :isVip="isVip" :u8Type="u8Type" />
  </div>
</template>

<script>
import Mobileview from '@/pages/welcomePage/content/Mobileview'
import Tabcontent from '@/pages/welcomePage/content/Tabcontent'
import PCview from '@/pages/welcomePage/content/PCview.vue'
export default {
  props: {
    u8Type: {
      type: Number,
      default: 0
    },
    isVip: {
      type: Boolean,
      default: false
    },
    dataList: {
      type: Array,
      defaults: []
    },
    toDay: {
      type: String,
      default: ''
    }
  },
  components: {
    'app-mobile-view': Mobileview,
    'app-tab-content': Tabcontent,
    'app-pc-view': PCview
  },
  methods: {
    getTypeYeekee: (typeYeekee) => {
      if (typeYeekee === 1) {
        return 'VIP'
      } else if (typeYeekee === 2) {
        return 'Express'
      } else {
        return ''
      }
    }
  },
  data: function () {
    return {
    }
  }
}
</script>

<style lang="less">
</style>
