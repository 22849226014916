<template>
  <div class="country">
    <div class="bgwhitealpha shadow-sm rounded p-2 xtarget " id="thaiStock">
      <h6 class="text-dark">
        <i class="fas fa-star"></i> {{ $t("lotto_thai") }}
        <span class="badge badge-dark">{{
           info.lottoDate ? dateParse(info.lottoDate) : ""
        }}</span>
      </h6>
      <div class="card-group">
        <div class="card border-dark text-center mb-8">
          <div class="card-header text-gold p-1">หวยรัฐบาล</div>
          <div class="card-body p-0">
            <div class="card border-dark text-center mb-2">
              <div class="card-header text-gold p-1"></div>
              <div class="card-body p-1">
                <p class="card-text">{{ info.first_prize ? info.first_prize : '' }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col pr-1">
                <div class="card border-dark text-center">
                  <div class="card-header text-gold p-1">3ตัวหน้า<br /></div>
                  <div class="card-body p-1">
                    <p class="card-text">{{  info.prefixThreeDigit ? info.prefixThreeDigit : '' }}</p>
                  </div>
                </div>
              </div>
              <div class="col px-1">
                <div class="card border-dark text-center">
                  <div class="card-header text-gold p-1">3ตัวล่าง<br /></div>
                  <div class="card-body p-1">
                    <p class="card-text">{{  info.suffixThreeDigit ? info.suffixThreeDigit : '' }}</p>
                  </div>
                </div>
              </div>
              <div class="col pl-1">
                <div class="card border-dark text-center">
                  <div class="card-header text-gold p-1">2ตัวล่าง<br /></div>
                  <div class="card-body p-1">
                    <p class="card-text">{{  info.twoDigit ? info.twoDigit : '' }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns'
export default {
  props: {
    info: {
      type: Object,
      defaults: {
        lottoDate: '',
        twoDigit: '',
        suffixThreeDigit: '',
        prefixThreeDigit: '',
        first_prize: ''
      }
    }
  },
  created () {
  },
  mounted () {
  },
  methods: {
    dateParse (date) {
      return format(Date.parse(date), 'dd MMMM yyyy')
    }
  }
}
</script>

<style>
</style>
