<template>
  <div class="tab-content" id="pills-tabContent">
    <div
      class="tab-pane fade show active"
      v-bind:id="getTabId('pills-short')"
      role="tabpanel"
      aria-labelledby="pills-short-tab"
    >
      <div class="carousel-view">
        <div class="bg-danger p-1 my-1 text-center text-white"></div>
        <div v-bind:id="getTabName()" class="carousel slide" data-ride="carousel">
          <ol id="nav2" class="carousel-indicators nav-slide2 nav-slide-yeekee">
            <li v-bind:data-target="'#'+getTabName()"
            v-bind:data-slide-to="index"
            v-for="(item, index) in dataList"
              v-bind:class="
                index === 0 ? 'active' : ''"
              v-bind:key="index">
              {{index + 1}}
            </li>
          </ol>
          <div class="d-flex justify-content-between py-1">
            <a
              v-bind:href="'#'+getTabName()"
              role="button"
              data-slide="prev"
              class="btn btn-dark btn-sm"
            >
              <span><i class="fas fa-chevron-left"></i> Previous</span>
            </a>
            <a
              v-bind:href="'#'+getTabName()"
              role="button"
              data-slide="next"
              class="btn btn-dark btn-sm"
            >
              <span>Next <i class="fas fa-chevron-right"></i></span>
            </a>
          </div>
          <div class="carousel-inner" role="listbox">
            <div
              v-for="(item, index) in dataList"
              v-bind:class="
                index === 0 ? 'carousel-item active' : 'carousel-item'
              "
              v-bind:key="index"
            >
              <div class="card border-secondary text-center mb-2">
                <div class="card-header text-gold p-1">
                  {{item.title}}
                </div>
                <div class="card-body p-0">
                  <div class="d-flex flex-row">
                    <div class="card text-center w-50 border-card-right m-0">
                      <div
                        class="card-header sub-card-header bg-transparent p-0"
                      >
                        3 ตัวบน<br />
                      </div>
                      <div class="card-body p-0">
                        <p class="card-text font-weight-bold text-black">{{ item.threeDigit }}</p>
                      </div>
                    </div>
                    <div class="card text-center w-50 border-card-right m-0">
                      <div
                        class="card-header sub-card-header bg-transparent p-0"
                      >
                        2 ตัวล่าง<br />
                      </div>
                      <div class="card-body p-0">
                        <p class="card-text font-weight-bold text-black">{{ item.twoDigit }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="tab-pane fade"
      v-bind:id="getTabId('pills-long')"
      role="tabpanel"
      aria-labelledby="pills-long-tab"
    >
      <div class="row px-0 m-0">
        <div
          class="col-12 col-sm-12 col-md-6 col-lg-3 px-1"
          v-for="(item, index) in dataList"
          v-bind:key="index"
        >
          <div class="card border-secondary text-center mb-2">
            <div class="card-header text-gold p-1" v-if="(item.title ? item.title.includes('-') : true)">
              {{item.title}}
            </div>
            <div class="card-header text-gold p-1" v-else>
              {{ $t("yeekee_title") }} {{ (isVip == true) ? "VIP" : '' }} - {{item.title}}
            </div>
            <div class="card-body p-0">
              <div class="d-flex flex-row">
                <div class="card text-center w-50 border-card-right m-0">
                  <div class="card-header sub-card-header bg-transparent p-0">
                    3 ตัวบน<br />
                  </div>
                  <div class="card-body p-0">
                    <p class="card-text font-weight-bold text-black">{{ item.threeDigit }}</p>
                  </div>
                </div>
                <div class="card text-center w-50 border-card-right m-0">
                  <div class="card-header sub-card-header bg-transparent p-0">
                    2 ตัวล่าง<br />
                  </div>
                  <div class="card-body p-0">
                    <p class="card-text font-weight-bold text-black">{{ item.twoDigit }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataList: {
      type: Array,
      defaults: []
    },
    isVip: {
      type: Boolean,
      defaults: false
    },
    u8Type: {
      type: Number,
      default: 0
    }
  },
  created: function () {
  },
  methods: {
    getTabName () {
      return 'myCarousel00' + this.u8Type
    },
    getTabId (name) {
      return name + this.u8Type
    }
  },
  data: function () {
    return {}
  }
}
</script>

<style lang="less">
</style>
