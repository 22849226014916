<template>
  <div class="pc-view">
    <div class="row px-0 m-0">
      <div class="col-12 col-sm-12 col-md-6 col-lg-3 px-1" v-for="(item, index) in dataList" v-bind:key="index">
        <div class="card border-secondary text-center mb-2">
          <div class="card-header text-gold p-1">{{item.title}}</div>
          <div class="card-body p-0">
            <div class="d-flex flex-row">
              <div class="card text-center w-50 border-card-right m-0">
                <div class="card-header sub-card-header bg-transparent p-0">3 ตัวบน<br /></div>
                <div class="card-body p-0">
                  <p class="card-text font-weight-bold text-black">{{ item.threeDigit }}</p>
                </div>
              </div>
              <div class="card text-center w-50 border-card-right m-0">
                <div class="card-header sub-card-header bg-transparent p-0">2 ตัวล่าง<br /></div>
                <div class="card-body p-0">
                  <p class="card-text font-weight-bold  text-black">{{ item.twoDigit }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isVip: {
      type: Boolean,
      default: false
    },
    dataList: {
      type: Array,
      defaults: []
    }
  },
  created: () => {},
  methods: {},
  data: () => {
    return {}
  }
}
</script>

<style>
.pc-view .bootstrap-select a {
  font-size: initial;
  text-shadow: initial;
  color: initial;
}
.pc-view .bootstrap-select {
  background: transparent;
  height: auto;
}
.pc-view .bootstrap-select .btn-sm {
  background: rgba(0, 0, 0, 0.2);
}
.pc-view .bootstrap-select .filter-option-inner {
  min-width: 20px;
  max-width: 20px;
}
.pc-view .bootstrap-select .filter-option-inner span {
  border-radius: 1px;
}
.pc-view .bootstrap-select > .dropdown-toggle {
  padding-right: 22px;
}

@media screen and (max-width: 768px) {
  .pc-view {
    display: none !important;
  }
}
</style>
