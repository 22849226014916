<template>
  <div class="bgwhitealpha shadow-sm rounded p-2 xtarget" id="foreignStock">
    <h6 class="text-dark">
      <i class="far fa-star"></i> {{ $t("lotto_huay_thai") }}
      <!-- <span class="badge badge-dark">{{ convertDay(dataList[0].) }}</span> -->
    </h6>
    <div class="">
      <div class="row px-0 m-0">
        <div
          v-for="(item, index) in dataList"
          v-bind:key="index"
          v-bind:class="
            item.isHalf
              ? 'col-12 col-sm-12 col-md-6 col-lg-6 px-1'
              : 'col-12 col-sm-12 col-md-6 col-lg-6 px-1'
          "
        >
          <div class="card border-dark text-center mb-2">
            <div class="card-header text-gold p-1">
              <span
                class="flag-icon"
                v-bind:class="getIconFlag(item.title)"
              ></span>
              {{ item.title }}
            </div>
            <div class="card-body p-0">
              <div class="d-flex flex-row">
                <div class="card text-center w-50 border-card-right m-0">
                  <div class="card-header sub-card-header bg-transparent p-0">
                    3ตัวบน<br />
                  </div>
                  <div class="card-body p-0">
                    <p class="card-text">
                      {{ item.threeDigit ? item.threeDigit : "xxx" }}
                    </p>
                  </div>
                </div>
                <div class="card text-center w-50 border-card-right m-0">
                  <div class="card-header sub-card-header bg-transparent p-0">
                    2ตัวล่าง<br />
                  </div>
                  <div class="card-body p-0">
                    <p class="card-text">
                      {{ item.twoDigit ? item.twoDigit : "xx" }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getFlagIcons } from '@/utils'
import { format } from 'date-fns'
export default {
  props: {
    dataList: {
      type: Array,
      defaults: []
    },
    toDay: {
      type: String,
      default: ''
    }
  },
  created: function () {},
  methods: {
    getIconFlag (title) {
      if (title) {
        return getFlagIcons(title)
      } else {
        return ''
      }
    },
    convertDay: (dateT) => {
      return format(new Date(dateT), 'dd MMMM yyyy')
    }
  },
  computed: {}
}
</script>

<style lang="less">
.pc-view .bootstrap-select a {
  font-size: initial;
  text-shadow: initial;
  color: initial;
}
.pc-view .bootstrap-select {
  background: transparent;
  height: auto;
}
.pc-view .bootstrap-select .btn-sm {
  background: rgba(0, 0, 0, 0.2);
}
.pc-view .bootstrap-select .filter-option-inner {
  min-width: 20px;
  max-width: 20px;
}
.pc-view .bootstrap-select .filter-option-inner span {
  border-radius: 1px;
}
.pc-view .bootstrap-select > .dropdown-toggle {
  padding-right: 22px;
}
@media screen and (max-width: 768px) {
  .pc-view {
    display: none !important;
  }
}
</style>
