<template>
  <div class="mobile-view">
    <ul class="nav nav-pills mb-3 w-100 bg-white rounded p-1" role="tablist">
      <li class="nav-item w-50">
        <a
          class="nav-link active btn btn-outline-dark btn-sm mx-1"
          id="pills-short-tab"
          data-toggle="pill"
          v-bind:href="getTabName('#pills-short')"
          role="tab"
          aria-controls="pills-short"
          aria-selected="true"
          >มุมมองสั้น</a
        >
      </li>
      <li class="nav-item w-50">
        <a
          class="nav-link btn btn-outline-dark btn-sm mx-1"
          id="pills-long-tab"
          data-toggle="pill"
          v-bind:href="getTabName('#pills-long')"
          role="tab"
          aria-controls="pills-long"
          aria-selected="false"
          >มุมมองยาว</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    dataList: {
      type: Array,
      defaults: []
    },
    isVip: {
      type: Boolean,
      defaults: false
    },
    u8Type: {
      type: Number,
      default: 0
    }
  },
  methods: {
    getTabName (name) {
      return name + this.u8Type
    }
  }
}
</script>

<style scoped>
@media screen and (max-width: 768px) {
  .mobile-view {
    display: block !important;
  }
}
.mobile-view {
  display: none;
}
</style>
