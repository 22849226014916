<template>
  <div class="row px-2">
    <div class="col-sm-12 col-md-6 col-lg-6 mb-2 px-1" v-for="(data, index) in dataList" :key="index">
      <div class="bgwhitealpha border-gold shadow-sm rounded p-2 h-100">
        <h6 class="text-dark">
          <i class="far fa-gem"></i> {{data.title}}
          <span class="badge badge-danger">{{data.lottoDate ? dateParse(data.lottoDate) : ''}}</span>
        </h6>
        <div class="row">
          <div class="col pr-1">
            <div class="card border-gold text-center">
              <div class="card-header text-gold p-1">3ตัว<br /></div>
              <div class="card-body p-1">
                <p class="card-text">{{data.threeDigit}}</p>
              </div>
            </div>
          </div>
          <div class="col pl-1">
            <div class="card border-gold text-center">
              <div class="card-header text-gold p-1">2ตัว<br /></div>
              <div class="card-body p-1">
                <p class="card-text">{{data.twoDigit}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns'
export default {
  props: {
    dataList: {
      type: Array,
      defaults: []
    }
  },
  mounted () {
  },
  methods: {
    dateParse (date) {
      return format(Date.parse(date), 'dd MMMM yyyy')
    }
  }
}
</script>

<style>
</style>
